<template>
    <div class="myspaceinfo_container">
        <h2 class="title_md right_container_sub_title">내 채널</h2>
        <div class="aplayz_channel myspace">
            <figure
                class="aplayz_channel_img_wrap cursor dim"
                @click="clickMyChannel($store.getters['player/getMyChannelInfo'], 'my')"
            >
                <div class="equalizer_wrap" v-if="isplay && isactivechannel && activetab === 'my'">
                    <equaliaer-comp />
                </div>
                <img v-if="mychannelinfo" class="aplayz_channel_img" :src="imgUrl" alt="" />
            </figure>
            <div class="aplayz_channel_nm_wrap">
                <h3
                    class="title_sm aplayz_channel_nm cursor"
                    @click="clickMyChannel($store.getters['player/getMyChannelInfo'], 'my')"
                >
                    {{ myChannelName }}
                </h3>
            </div>
        </div>
        <h3
            class="title_md right_container_sub_title right_container_sub_title1 mt_28"
            v-if="similarChannelsList.length > 0"
        >
            유사 채널
        </h3>
        <ul
            class="similarity_list"
            v-if="similarChannelsList.length > 0"
            :class="{ no_recommend: similarChannelsList.length === 0 }"
        >
            <li
                class="aplayz_channel mb16 cursor"
                v-for="(item, idx) in similarChannelsList"
                :id="`similar_${item.channelId}`"
                :key="item.channelId"
                @click="clickMyChannel(item, 'similar')"
            >
                <figure class="aplayz_channel_img_wrap">
                    <div
                        class="equalizer_wrap"
                        v-if="isplay && item.channelId === $store.getters.gettersChannelId && activetab === 'similar'"
                    >
                        <equaliaer-comp />
                    </div>
                    <img class="aplayz_channel_img" :src="item.brandId ? item.src : item.spaceImg" :alt="`유사채널_${idx}`" />
                </figure>
                <div class="aplayz_channel_nm_wrap">
                    <h3 class="title_sm aplayz_channel_nm">{{ item.spaceNm }}</h3>
                </div>
            </li>
        </ul>
        <h3 class="title_md right_container_sub_title mt_28">즐겨찾기</h3>
        <ul
            class="similarity_list scroll"
            v-if="bookmarkChannelsList.length > 0"
            :class="{ no_recommend: similarChannelsList.length === 0, bookmark: similarChannelsList.length > 0 }"
        >
            <li
                class="aplayz_channel mb16"
                v-for="item in bookmarkChannelsList"
                :key="item.channelId"
                :id="`bookmark_${item.channelId}`"
            >
                <figure class="aplayz_channel_img_wrap cursor" @click="clickMyChannel(item, 'bookmark')">
                    <div class="equalizer_wrap" v-if="isplay && item.channelId === $store.getters.gettersChannelId">
                        <equaliaer-comp />
                    </div>
                    <img class="aplayz_channel_img" :src="`${baseUrl}${item.imgUrl}`" :alt="item.spaceNm" />
                </figure>
                <div id="channelname_parent" class="aplayz_channel_nm_wrap">
                    <h3 class="title_sm aplayz_channel_nm cursor" @click="clickMyChannel(item, 'bookmark')">
                        {{ item.spaceNm }}
                    </h3>
                </div>
                <figure class="star_bookmark_wrap cursor" @click="setManageBookMark(item.channelId)">
                    <img src="/media/img/star_after.png" alt="즐겨찾기" class="star star_bookmark" />
                </figure>
            </li>
        </ul>
        <p v-else class="no_list">마음에 드는 채널을 즐겨찾기 추가하시면, 더 편하게 이용하실 수 있어요.</p>
    </div>
</template>
<script>
import rolling from '@/utils/directive/rolling';
export default {
  name: 'MySpaceInfo',
  directives: {
    rolling
  },
  props: {
    isplay: {
      type: Boolean,
      required: false,
      default: false
    },
    isactivechannel: {
      type: Boolean,
      required: false,
      default: false
    },
    activetab: {
      type: String,
      required: false,
      default: ''
    },
    mychannelinfo: {
      type: Object,
      requried: false,
      default: () => {
        return {
          brandId: '',
          brandImg: '',
          channelId: '',
          channelType: '',
          domesticRate: '0',
          genre: '',
          imgUrl: '',
          period: '',
          playerinfofn: () => {},
          sector: '',
          spaceId: '',
          spaceImg: '',
          spaceNm: '',
          spaceType: '',
          src: ''
        };
      }
    },
    clickchannel: {
      type: Function,
      required: false,
      default: () => {
        return () => {};
      }
    }
  },
  data () {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL
    };
  },
  components: {
    'equaliaer-comp': () => import(`@/components/player/Equalizer.vue`)
  },
  methods: {
    clickMyChannel (item, type) {
      this.clickchannel(item, type);
    },
    setManageBookMark (channelId) {
      const addArray = [
        ...this.$store.getters['player/getPopularityChannelList'],
        ...this.$store.getters['player/getDesignatedRecommendedChannels']
      ];
      const findChannelId = addArray.find(item => item.channelId === channelId);
      const recomId = findChannelId.recomId;
      this.$store.dispatch('player/manageBookmark', {
        channelId: recomId
      });
    }
  },
  computed: {
    imgUrl () {
      if (this.mychannelinfo) {
        if (this.mychannelinfo.brandId) {
          return this.mychannelinfo.imgUrl;
        }
        return this.mychannelinfo.spaceImg;
      }

      return '';
    },
    myChannelName () {
      if (this.mychannelinfo) {
        return this.mychannelinfo.spaceNm;
      }

      return '';
    },
    similarChannelsList () {
      return this.$store.getters['player/getSimilarChannelList'];
    },

    bookmarkChannelsList () {
      return this.$store.getters['player/getBookmarkChannels'];
    }
  }
};
</script>
<style scoped src="@/assets/css/player/myspaceinfo.css"></style>
